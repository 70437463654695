import { ref, reactive, computed } from 'vue'
import { defineStore } from 'pinia'
import { FetchError } from 'ofetch'
import { jwtDecode } from 'jwt-decode'
import { useGift } from '@/composables/api/useGift'
import { useOtp } from '@/composables/api/useOtp'
import { persistedState } from '#imports'

export const useGuestStore = defineStore(
  'guest',
  () => {
    const guest = reactive<{
      email: string | undefined
      storeId: string | undefined
      userId: string | undefined
      token: string | undefined
      loggedInToken: string | undefined
    }>({
      email: '',
      storeId: '',
      userId: '',
      token: '',
      loggedInToken: '',
    })
    const gift = ref()
    const giftAmount = computed(() =>
      gift.value?.usage?.type === 'freeItem'
        ? gift.value.usage?.budget
        : gift.value?.usage?.type === 'giftCard'
        ? gift.value?.usage?.balance
        : 0,
    )

    const requestError = ref<FetchError | null>()

    const { getGiftById } = useGift()
    const { guestLogin } = useOtp()

    const loadGift = async (id: string) => {
      const { data, error } = await getGiftById(id)
      if (data.value) {
        gift.value = data.value
        ;({
          email: guest.email,
          storeId: guest.storeId,
          userId: guest.userId,
        } = data.value)
      } else if (error.value) {
        requestError.value = error.value
      }
    }

    const loginGuestUser = async () => {
      const { data, error } = await guestLogin({
        userEmail: guest.email ?? '',
        userId: guest.userId ?? '',
        storeId: guest.storeId ?? '',
      })

      if (data.value) {
        guest.token = data.value.token
      } else if (error.value) {
        requestError.value = error.value
      }
    }

    const reset = () => {
      gift.value = null
      guest.email = ''
      guest.storeId = ''
      guest.userId = ''
      guest.token = ''
      guest.loggedInToken = ''
      requestError.value = null
    }

    const customerName = computed<string>(() => {
      if (guest.loggedInToken) {
        const decodedToken = jwtDecode<{ firstName: string }>(
          guest.loggedInToken,
        )
        return decodedToken?.firstName ?? ''
      }
      return ''
    })

    return {
      guest,
      gift,
      giftAmount,
      requestError,
      loadGift,
      loginGuestUser,
      reset,
      customerName,
    }
  },
  {
    persist: [
      {
        storage: persistedState.cookiesWithOptions({
          sameSite: 'strict',
        }),
      },
    ],
  },
)
