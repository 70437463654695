import type { UseFetchOptions } from '#app'
import { useZenniFetch } from '@/composables/useZenniFetch'
import type {
  CampaignGiftGetResponseBody,
  CampaignGiftGuestGetResponseBody,
} from '@/types/redemption'

export const useGift = () => {
  const getGiftHandle = (data: any[] | null, isEyebot: boolean) => {
    if (
      !data ||
      data.length === 0 ||
      data.filter(
        ({ isActivated, isUsed }: any) =>
          isActivated && (isEyebot ? !isUsed : true),
      ).length === 0
    ) {
      return null
    }
    return data.filter(
      ({ isActivated, isUsed }: any) =>
        isActivated && (isEyebot ? !isUsed : true),
    )[0]
  }

  const getGift = async (opts: any): Promise<CampaignGiftGetResponseBody> => {
    const { data, error } = await useZenniFetch<CampaignGiftGetResponseBody[]>(
      `/redemption/api/campaign/gift`,
      opts,
    )
    const gift = getGiftHandle(data.value, !!opts?.params?.idempotencyKey)
    if (error.value) {
      throw new Error('can not get available policy id')
    }
    return gift
  }

  const getGiftById = (id: string, opts?: UseFetchOptions<any>) => {
    return useZenniFetch<any>(`/redemption/api/campaign/gift/${id}`, opts)
  }

  const findGift = (
    email: string,
    opts?: UseFetchOptions<CampaignGiftGuestGetResponseBody>,
  ) => {
    return useZenniFetch<CampaignGiftGuestGetResponseBody>(
      `/redemption/api/campaign/gift/guest`,
      {
        ...opts,
        method: 'GET',
        query: {
          email,
        },
      },
    )
  }

  return {
    getGift,
    getGiftById,
    findGift,
  }
}
