import { useZenniFetch } from '../useZenniFetch'
import type {
  MagicLinkLoginReqInput,
  MagicLinkLoginResBody,
  OtpStartReqInput,
  GuestLoginReqInput,
} from '@/types/magicLink'

export const useOtp = () => {
  const start = (params: OtpStartReqInput) => {
    params.loginType = 'otp.start'
    return useZenniFetch(`/redemption/api/user/login`, {
      method: 'POST',
      body: params,
    })
  }

  const verify = (params: MagicLinkLoginReqInput) => {
    params.loginType = 'otp.login'
    return useZenniFetch<MagicLinkLoginResBody>(`/redemption/api/user/login`, {
      method: 'POST',
      body: params,
    })
  }

  const guestLogin = (params: GuestLoginReqInput) => {
    params.loginType = 'guest.login'
    return useZenniFetch<MagicLinkLoginResBody>(`/redemption/api/user/login`, {
      method: 'POST',
      body: params,
      headers: {
        'x-zenni-store-id': params.storeId,
      },
    })
  }

  return {
    start,
    verify,
    guestLogin,
  }
}
