import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { FetchError } from 'ofetch'
import type {
  AddItemsToCartRequest,
  UpdateBundleItemsRequest,
  CartResponseSuccess,
  ShippingInformationRequest,
} from '@/types/cart'
import { useGuestCart } from '~/composables/api/useGuestCart'
import { persistedState } from '#imports'

export const useGuestCartStore = defineStore(
  'guestCart',
  () => {
    const cartId = ref<string | null>(null)
    const cart = ref<CartResponseSuccess | null>(null)
    const cartApi = useGuestCart()
    const requestError = ref<FetchError | null>(null)
    const loading = ref<boolean>(false)

    const prices = computed(() => cart.value?.prices)
    const itemCount = computed(
      () => Object.keys(cart.value?.bundle_map ?? {}).length,
    )

    const createCart = async () => {
      loading.value = true
      try {
        const { data, error } = await cartApi.create()
        if (data.value) {
          cartId.value = data.value.cartId
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const loadCart = async () => {
      loading.value = true
      try {
        const { data, error } = await cartApi.get(cartId.value ?? '')
        if (data.value) {
          cart.value = data.value
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const initCart = async () => {
      if (!cartId.value) {
        await createCart()
      }
      if (cartId.value && !cart.value) {
        await loadCart()
      }
    }

    const addItems = async (items: AddItemsToCartRequest) => {
      loading.value = true
      try {
        const { data, error } = await cartApi.addItems(cart.value!.id, items)
        if (data.value) {
          cart.value = data.value
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const removeBundle = async (bundleId: string) => {
      loading.value = true
      try {
        const { data, error } = await cartApi.removeBundle(
          cart.value!.id,
          bundleId,
        )
        if (data.value) {
          cart.value = data.value
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const updateBundle = async (
      bundleId: string,
      params: UpdateBundleItemsRequest,
    ) => {
      loading.value = true
      try {
        const { data, error } = await cartApi.updateBundle(
          cart.value!.id,
          bundleId,
          params,
        )
        if (data.value) {
          cart.value = data.value
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const addShipping = async (params: ShippingInformationRequest) => {
      loading.value = true
      try {
        const { data, error } = await cartApi.addShipping(
          cart.value!.id,
          params,
        )
        if (data.value) {
          cart.value = data.value.cart
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const reset = () => {
      cartId.value = null
      cart.value = null
    }

    return {
      cart,
      cartId,
      prices,
      itemCount,
      loading,
      createCart,
      loadCart,
      initCart,
      addItems,
      removeBundle,
      updateBundle,
      addShipping,
      reset,
    }
  },
  {
    persist: [
      {
        storage: persistedState.cookiesWithOptions({
          sameSite: 'strict',
        }),
        paths: ['cartId'],
      },
    ],
  },
)
