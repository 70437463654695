import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app'
import { jwtDecode } from 'jwt-decode'
import type { UserJWT } from '@/types/jwt'
import { useGuestStore } from '~/store/guest'

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.meta.skipMiddleware) return

  if (to.path === '/welcome') return

  const { guest, requestError, loginGuestUser, reset } = useGuestStore()

  if (guest?.token) {
    const { exp } = jwtDecode<UserJWT>(guest.token)
    const expirationDate = new Date((exp || 0) * 1000)
    const currentDate = new Date()
    const FIVE_MINUTES = 5 * 60 * 1000

    if (expirationDate.getTime() - currentDate.getTime() <= FIVE_MINUTES) {
      await loginGuestUser()

      if (requestError) {
        /* eslint-disable-next-line */
        console.error('Error refreshing token:', requestError)
        reset()
        return navigateTo('/welcome')
      }
    }
  }
})
