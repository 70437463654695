import { default as _91_46_46_46slug_93vEbwI6fcI9Meta } from "/usr/app/rdp-employee/pages/[...slug].vue?macro=true";
import { default as cart_45previewy2HDBvM418Meta } from "/usr/app/rdp-employee/pages/cart-preview.vue?macro=true";
import { default as addressCpiSuMMSC7Meta } from "/usr/app/rdp-employee/pages/checkout/address.vue?macro=true";
import { default as indexp3ZfysfrN8Meta } from "/usr/app/rdp-employee/pages/checkout/index.vue?macro=true";
import { default as review4PTmuxP0pmMeta } from "/usr/app/rdp-employee/pages/checkout/review.vue?macro=true";
import { default as contactsorSRZoIcuMeta } from "/usr/app/rdp-employee/pages/contact.vue?macro=true";
import { default as index91S7VYKen5Meta } from "/usr/app/rdp-employee/pages/index.vue?macro=true";
import { default as indexj6H6uLbZkEMeta } from "/usr/app/rdp-employee/pages/products/[slug]/index.vue?macro=true";
import { default as index2D5KpkxiC4Meta } from "/usr/app/rdp-employee/pages/products/[slug]/prescription/index.vue?macro=true";
import { default as indexCV2FwRoYUkMeta } from "/usr/app/rdp-employee/pages/products/[slug]/select-lens-type/index.vue?macro=true";
import { default as _91_46_46_46slug_937SAxlLJV7sMeta } from "/usr/app/rdp-employee/pages/products/list/[...slug].vue?macro=true";
import { default as index6qSAzULLjTMeta } from "/usr/app/rdp-employee/pages/products/list/index.vue?macro=true";
import { default as redeemeddiXHYkvoi1Meta } from "/usr/app/rdp-employee/pages/redeemed.vue?macro=true";
import { default as employeeHpRup6pJqFMeta } from "/usr/app/rdp-employee/pages/terms-of-use/employee.vue?macro=true";
import { default as indexX7Bsnp6I8SMeta } from "/usr/app/rdp-employee/pages/terms-of-use/employer/index.vue?macro=true";
import { default as v1bjewBbIoydMeta } from "/usr/app/rdp-employee/pages/terms-of-use/employer/v1.vue?macro=true";
import { default as index8dBTAxnJD8Meta } from "/usr/app/rdp-employee/pages/user/order/index.vue?macro=true";
import { default as verifyhazuPzoVNjMeta } from "/usr/app/rdp-employee/pages/verify.vue?macro=true";
import { default as welcomeIIKKoeyeTmMeta } from "/usr/app/rdp-employee/pages/welcome.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93vEbwI6fcI9Meta?.name ?? "slug",
    path: _91_46_46_46slug_93vEbwI6fcI9Meta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93vEbwI6fcI9Meta || {},
    alias: _91_46_46_46slug_93vEbwI6fcI9Meta?.alias || [],
    redirect: _91_46_46_46slug_93vEbwI6fcI9Meta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: cart_45previewy2HDBvM418Meta?.name ?? "cart-preview",
    path: cart_45previewy2HDBvM418Meta?.path ?? "/cart-preview",
    meta: cart_45previewy2HDBvM418Meta || {},
    alias: cart_45previewy2HDBvM418Meta?.alias || [],
    redirect: cart_45previewy2HDBvM418Meta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/cart-preview.vue").then(m => m.default || m)
  },
  {
    name: addressCpiSuMMSC7Meta?.name ?? "checkout-address",
    path: addressCpiSuMMSC7Meta?.path ?? "/checkout/address",
    meta: addressCpiSuMMSC7Meta || {},
    alias: addressCpiSuMMSC7Meta?.alias || [],
    redirect: addressCpiSuMMSC7Meta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/checkout/address.vue").then(m => m.default || m)
  },
  {
    name: indexp3ZfysfrN8Meta?.name ?? "checkout",
    path: indexp3ZfysfrN8Meta?.path ?? "/checkout",
    meta: indexp3ZfysfrN8Meta || {},
    alias: indexp3ZfysfrN8Meta?.alias || [],
    redirect: indexp3ZfysfrN8Meta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: review4PTmuxP0pmMeta?.name ?? "checkout-review",
    path: review4PTmuxP0pmMeta?.path ?? "/checkout/review",
    meta: review4PTmuxP0pmMeta || {},
    alias: review4PTmuxP0pmMeta?.alias || [],
    redirect: review4PTmuxP0pmMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/checkout/review.vue").then(m => m.default || m)
  },
  {
    name: contactsorSRZoIcuMeta?.name ?? "contact",
    path: contactsorSRZoIcuMeta?.path ?? "/contact",
    meta: contactsorSRZoIcuMeta || {},
    alias: contactsorSRZoIcuMeta?.alias || [],
    redirect: contactsorSRZoIcuMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: index91S7VYKen5Meta?.name ?? "index",
    path: index91S7VYKen5Meta?.path ?? "/",
    meta: index91S7VYKen5Meta || {},
    alias: index91S7VYKen5Meta?.alias || [],
    redirect: index91S7VYKen5Meta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexj6H6uLbZkEMeta?.name ?? "products-slug",
    path: indexj6H6uLbZkEMeta?.path ?? "/products/:slug()",
    meta: indexj6H6uLbZkEMeta || {},
    alias: indexj6H6uLbZkEMeta?.alias || [],
    redirect: indexj6H6uLbZkEMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/products/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index2D5KpkxiC4Meta?.name ?? "products-slug-prescription",
    path: index2D5KpkxiC4Meta?.path ?? "/products/:slug()/prescription",
    meta: index2D5KpkxiC4Meta || {},
    alias: index2D5KpkxiC4Meta?.alias || [],
    redirect: index2D5KpkxiC4Meta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/products/[slug]/prescription/index.vue").then(m => m.default || m)
  },
  {
    name: indexCV2FwRoYUkMeta?.name ?? "products-slug-select-lens-type",
    path: indexCV2FwRoYUkMeta?.path ?? "/products/:slug()/select-lens-type",
    meta: indexCV2FwRoYUkMeta || {},
    alias: indexCV2FwRoYUkMeta?.alias || [],
    redirect: indexCV2FwRoYUkMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/products/[slug]/select-lens-type/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_937SAxlLJV7sMeta?.name ?? "products-list-slug",
    path: _91_46_46_46slug_937SAxlLJV7sMeta?.path ?? "/products/list/:slug(.*)*",
    meta: _91_46_46_46slug_937SAxlLJV7sMeta || {},
    alias: _91_46_46_46slug_937SAxlLJV7sMeta?.alias || [],
    redirect: _91_46_46_46slug_937SAxlLJV7sMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/products/list/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index6qSAzULLjTMeta?.name ?? "products-list",
    path: index6qSAzULLjTMeta?.path ?? "/products/list",
    meta: index6qSAzULLjTMeta || {},
    alias: index6qSAzULLjTMeta?.alias || [],
    redirect: index6qSAzULLjTMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/products/list/index.vue").then(m => m.default || m)
  },
  {
    name: redeemeddiXHYkvoi1Meta?.name ?? "redeemed",
    path: redeemeddiXHYkvoi1Meta?.path ?? "/redeemed",
    meta: redeemeddiXHYkvoi1Meta || {},
    alias: redeemeddiXHYkvoi1Meta?.alias || [],
    redirect: redeemeddiXHYkvoi1Meta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/redeemed.vue").then(m => m.default || m)
  },
  {
    name: employeeHpRup6pJqFMeta?.name ?? "terms-of-use-employee",
    path: employeeHpRup6pJqFMeta?.path ?? "/terms-of-use/employee",
    meta: employeeHpRup6pJqFMeta || {},
    alias: employeeHpRup6pJqFMeta?.alias || [],
    redirect: employeeHpRup6pJqFMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/terms-of-use/employee.vue").then(m => m.default || m)
  },
  {
    name: indexX7Bsnp6I8SMeta?.name ?? "terms-of-use-employer",
    path: indexX7Bsnp6I8SMeta?.path ?? "/terms-of-use/employer",
    meta: indexX7Bsnp6I8SMeta || {},
    alias: indexX7Bsnp6I8SMeta?.alias || [],
    redirect: indexX7Bsnp6I8SMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/terms-of-use/employer/index.vue").then(m => m.default || m)
  },
  {
    name: v1bjewBbIoydMeta?.name ?? "terms-of-use-employer-v1",
    path: v1bjewBbIoydMeta?.path ?? "/terms-of-use/employer/v1",
    meta: v1bjewBbIoydMeta || {},
    alias: v1bjewBbIoydMeta?.alias || [],
    redirect: v1bjewBbIoydMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/terms-of-use/employer/v1.vue").then(m => m.default || m)
  },
  {
    name: index8dBTAxnJD8Meta?.name ?? "user-order",
    path: index8dBTAxnJD8Meta?.path ?? "/user/order",
    meta: index8dBTAxnJD8Meta || {},
    alias: index8dBTAxnJD8Meta?.alias || [],
    redirect: index8dBTAxnJD8Meta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/user/order/index.vue").then(m => m.default || m)
  },
  {
    name: verifyhazuPzoVNjMeta?.name ?? "verify",
    path: verifyhazuPzoVNjMeta?.path ?? "/verify",
    meta: verifyhazuPzoVNjMeta || {},
    alias: verifyhazuPzoVNjMeta?.alias || [],
    redirect: verifyhazuPzoVNjMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: welcomeIIKKoeyeTmMeta?.name ?? "welcome",
    path: welcomeIIKKoeyeTmMeta?.path ?? "/welcome",
    meta: welcomeIIKKoeyeTmMeta || {},
    alias: welcomeIIKKoeyeTmMeta?.alias || [],
    redirect: welcomeIIKKoeyeTmMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/welcome.vue").then(m => m.default || m)
  }
]