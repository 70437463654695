import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app'
import { useGuestStore } from '~/store/guest'
import { useGuestCartStore } from '~/store/guestCart'
import { useSiteConfigurationStore } from '@/store/siteConfiguration'

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.meta.skipMiddleware) return

  const { gift: giftQueryParam } = to.query
  const guestStore = useGuestStore()
  const guestCartStore = useGuestCartStore()
  const { getSiteConfiguration } = useSiteConfigurationStore()

  if (to.meta.unauthorized) {
    guestStore.reset()
    guestCartStore.reset()
    return
  }

  if (
    !guestStore.gift?.id ||
    !guestStore.guest?.token ||
    (giftQueryParam && guestStore.gift?.id !== String(giftQueryParam))
  ) {
    guestStore.reset()
    guestCartStore.reset()

    if (giftQueryParam) {
      await guestStore.loadGift(giftQueryParam as string)
      if (!guestStore.gift?.id) {
        return navigateTo('/welcome')
      }
      const storeCode = guestStore.gift.code.replaceAll('_', ' ') as string
      await Promise.all([
        getSiteConfiguration(storeCode),
        guestStore.loginGuestUser(),
      ])
      if (guestStore.requestError) {
        return navigateTo('/welcome')
      }
    } else {
      return navigateTo('/welcome')
    }
  } else if (guestStore.gift?.id && !giftQueryParam) {
    return navigateTo({
      path: to.path,
      query: { ...to.query, gift: guestStore.gift?.id },
    })
  }
})
