import revive_payload_client_An2VkCsgqe from "/usr/app/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2_vue-tsc@1.8.19/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9SwQNzI8BF from "/usr/app/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2_vue-tsc@1.8.19/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7LnnfXU1Xa from "/usr/app/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2_vue-tsc@1.8.19/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_II6O2I1T54 from "/usr/app/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2_vue-tsc@1.8.19/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_eXxbg6OJ7O from "/usr/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.2.2_vue@3.4.20/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/app/rdp-employee/.nuxt/components.plugin.mjs";
import prefetch_client_D85M7xAtFX from "/usr/app/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2_vue-tsc@1.8.19/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_1EHSFtVhGe from "/usr/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.7.4_vue@3.4.20/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_iRsgVXnzrS from "/usr/app/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2_vue-tsc@1.8.19/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_f5nwKA2y4D from "/usr/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.1.2_@pinia+nuxt@0.4.11_pinia@2.1.7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import algolia_UEeqj5fYkj from "/usr/app/base/plugins/algolia.ts";
import contentful_nJvUH9jWIP from "/usr/app/base/plugins/contentful.ts";
import magentoCookieState_RkPgdzD2l8 from "/usr/app/base/plugins/magentoCookieState.ts";
import vue_query_sbXI9EtIIL from "/usr/app/base/plugins/vue-query.ts";
import vue_tailwind_datepicker_client_0hcucy5UBY from "/usr/app/base/plugins/vue-tailwind-datepicker.client.ts";
import vue_tel_input_client_p4bs0Ud1JC from "/usr/app/base/plugins/vue-tel-input.client.ts";
import algolia_config_m5rTH05vbp from "/usr/app/rdp-employee/plugins/algolia-config.ts";
import api_GFfDXud5Cr from "/usr/app/rdp-employee/plugins/api.ts";
import braintree_client_RKQioOlrbn from "/usr/app/rdp-employee/plugins/braintree.client.ts";
import contentKey_kG2JAak4UX from "/usr/app/rdp-employee/plugins/contentKey.ts";
import fittingboxPlugin_fNhCdApc9T from "/usr/app/rdp-employee/plugins/fittingboxPlugin.ts";
import tippy_xFBYqfAPCu from "/usr/app/rdp-employee/plugins/tippy.ts";
import vtoScanPlugin_JSUOmvBKkO from "/usr/app/rdp-employee/plugins/vtoScanPlugin.ts";
import vue_the_mask_uOehdyQKKA from "/usr/app/rdp-employee/plugins/vue-the-mask.ts";
import vue_touch_Ovr4VGXenq from "/usr/app/rdp-employee/plugins/vue-touch.js";
export default [
  revive_payload_client_An2VkCsgqe,
  unhead_9SwQNzI8BF,
  router_7LnnfXU1Xa,
  payload_client_II6O2I1T54,
  plugin_vue3_eXxbg6OJ7O,
  components_plugin_KR1HBZs4kY,
  prefetch_client_D85M7xAtFX,
  plugin_1EHSFtVhGe,
  chunk_reload_client_iRsgVXnzrS,
  plugin_f5nwKA2y4D,
  algolia_UEeqj5fYkj,
  contentful_nJvUH9jWIP,
  magentoCookieState_RkPgdzD2l8,
  vue_query_sbXI9EtIIL,
  vue_tailwind_datepicker_client_0hcucy5UBY,
  vue_tel_input_client_p4bs0Ud1JC,
  algolia_config_m5rTH05vbp,
  api_GFfDXud5Cr,
  braintree_client_RKQioOlrbn,
  contentKey_kG2JAak4UX,
  fittingboxPlugin_fNhCdApc9T,
  tippy_xFBYqfAPCu,
  vtoScanPlugin_JSUOmvBKkO,
  vue_the_mask_uOehdyQKKA,
  vue_touch_Ovr4VGXenq
]