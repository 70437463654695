import validate from "/usr/app/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2_vue-tsc@1.8.19/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45guest_45init_45global from "/usr/app/rdp-employee/middleware/01.guest-init.global.ts";
import _02_45refresh_45guest_45global from "/usr/app/rdp-employee/middleware/02.refresh-guest.global.ts";
export const globalMiddleware = [
  validate,
  _01_45guest_45init_45global,
  _02_45refresh_45guest_45global
]
export const namedMiddleware = {
  "check-redeemed": () => import("/usr/app/rdp-employee/middleware/check-redeemed.ts")
}