<template>
  <Body class="h-full" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useSiteConfigurationStore } from '@/store/siteConfiguration'
import { useHead } from '#imports'

const siteConfigurationStore = useSiteConfigurationStore()

useHead({
  title:
    siteConfigurationStore.siteConfiguration?.configuration?.seoConfig?.title,
  meta: [
    {
      name: 'description',
      content:
        siteConfigurationStore.siteConfiguration?.configuration?.seoConfig
          ?.description,
    },
  ],
  script:
    siteConfigurationStore.siteConfiguration?.configuration?.seoConfig?.script,
})
</script>
