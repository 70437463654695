import { navigateTo } from 'nuxt/app'
import { jwtDecode } from 'jwt-decode'
import type { UserJWT } from '@/types/jwt'
import { defineNuxtPlugin } from '#imports'
import { useGuestStore } from '~/store/guest'

export default defineNuxtPlugin((nuxtApp: any) => {
  const api = $fetch.create({
    onRequest({ options }) {
      const { guest } = useGuestStore()

      if (guest.loggedInToken || guest.token) {
        if (options.headers) {
          return
        }
        options.headers ||= {}
        options.headers['x-authorization-obo'] = `Bearer ${
          guest.loggedInToken || guest.token
        }`
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        const { guest, reset: logout } = useGuestStore()
        const { loggedInToken } = guest

        if (loggedInToken) {
          const { exp } = jwtDecode<UserJWT>(loggedInToken)
          if (exp * 1000 < Date.now()) {
            // we need to clear loggedInToken in guest store before as the verify page will check for loggedInToken before mounting
            // otherwise will cause an infinite loop going back to cart-preview
            guest.loggedInToken = ''
            await nuxtApp.runWithContext(() => navigateTo('/verify'))
            return
          }
        }

        logout()
        await nuxtApp.runWithContext(() => navigateTo('/welcome'))
      }
    },
  })

  // Expose to useNuxtApp().$api
  return {
    provide: {
      api,
    },
  }
})
